.url-container {
	width: 100%;
	display: flex;
}

.url-container select {
	width: 100px;
	margin-right: 15px;
}

.requestbuilder-options-container {
	width: 100%;
	margin-bottom: 15px;
}

.requestbuilder-option-header {
	width: 100%;
	display: flex;
	margin-bottom: 15px;
}

.requestbuilder-option-header h4 {
	margin-right: 15px;
	padding: 5px;
}

.requestbuilder-option-header h4:hover {
	cursor: pointer;
	color: #f45142;
}

.requestbuilder-settings {
	display: flex;
	align-items: center;
}

.requestbuilder-settings span {
	margin-left: 15px;
}

.requestbuilder-option-header h4.selected {
	color: #f45142;
	border-bottom: 3px solid #f45142;
}

.requestbuilder-option-header h4.disabled {
	opacity: 0.7;
}

.requestbuilder-option-header h4.disabled:hover {
	cursor: not-allowed;
	color: #fff;
}

.requestbuilder-options-container {
	max-height: 1000px;
	overflow-y: auto;
	padding: 5px 5px;
}

.requestbuilder-options-container::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

.variable-value span:hover,
.variable-value div:hover {
	cursor: pointer !important;
	color: green !important;
	/* opacity: 0.7; */
	font-weight: 700;
}

.requestbuilder-options-container ::-webkit-scrollbar-button {
	display: none;
}

.requestbuilder-options-container ::-webkit-scrollbar-thumb {
	background-color: #878787;
	border-radius: 8px;
	cursor: pointer;
	border: 3px solid transparent;
	background-clip: content-box;
}

.requestbuilder-option {
	display: flex;
	align-items: center;
}
