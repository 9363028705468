.premium-plan-quantity {
	display: flex;
	align-items: center;
	justify-content: center;
}
input,
textarea {
	border: 1px solid #eeeeee;
	box-sizing: border-box;
	margin: 0;
	outline: none;
	padding: 10px;
}

input[type="button"] {
	-webkit-appearance: button;
	cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.input-group {
	clear: both;
	position: relative;
}

.input-group input[type="button"] {
	background-color: #eeeeee;
	min-width: 38px;
	width: auto;
	transition: all 300ms ease;
}

.input-group .button-minus,
.input-group .button-plus {
	font-weight: bold;
	height: 38px;
	padding: 0;
	width: 38px;
	position: relative;
}

.input-group .quantity-field {
	position: relative;
	height: 38px;
	left: -6px;
	text-align: center;
	width: 62px;
	display: inline-block;
	font-size: 13px;
	margin: 0 0 5px;
	resize: vertical;
	background-color: #fff !important;
	color: inherit;
}

.button-plus {
	left: -13px;
}

input[type="number"] {
	-moz-appearance: textfield;
	-webkit-appearance: none;
}

.premium-plan-quantity span {
	color: #fff;
	font-weight: 800;
	font-size: 18px;
	text-align: center;
}

.prem-row {
	align-items: center;
}

.most-popular {
	height: 425px !important;
}

.premium-plan-ultimate.most-popular {
	height: 500px !important;
}
