@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {}

@layer components {}

textarea {
	width: 100%;
	font-size: 14px !important;
	background-color: #36393f !important;
	border: 1px solid #000000;
	color: #fff;
	transition: border-color 0.2s ease-in-out;
	border-radius: 3px;
	padding: 15px 70px 35px 15px;
	font-weight: 500;
	outline: none;
	box-sizing: border-box;
	min-height: 150px;
	resize: none;
}

select {
	padding-left: 20px;
	width: 100%;
	background-color: #36393f;
	border: 1px solid #000;
	color: #fff;
	transition: border-color 0.2s ease-in-out;
	width: 100%;
	border-radius: 12px;
	font-size: 1rem;
	font-weight: 500;
	height: 50px;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}