.discord-messages {
	color: #fff;
	background-color: #36393e;
	font-size: 16px;
	font-family: Roboto, sans-serif;
	line-height: 170%;
	margin-top: 25px;
	border: 1px solid rgba(255, 255, 255, 0.05);
	font-family: "Open Sans", sans-serif;
}

.discord-message {
	color: #dcddde;
	display: flex;
	flex-direction: column;
	font-size: 0.9em;
	margin: 1em 0;
	padding: 0.25em 1em 0;
}

.discord-interaction {
	color: #b9bbbe;
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	font-size: 0.95em;
	margin-bottom: 4px;
	padding-left: 56px;
	line-height: 150%;
	box-sizing: border-box;
}

.discord-interaction:before {
	content: "";
	display: block;
	position: absolute;
	width: 33px;
	top: 50%;
	bottom: 0;
	left: 18px;
	margin: -1px 0 0;
	border-top-left-radius: 6px;
	border-top: 2px solid #4f545c;
	border-left: 2px solid #4f545c;
	box-sizing: border-box;
}

.discord-interaction .discord-interaction-author-avatar {
	width: 16px;
	height: 16px;
	margin-right: 4px;
	border-radius: 50%;
}

.discord-interaction .discord-interaction-author-info {
	font-size: 1em;
	flex-direction: row-reverse;
}

.discord-message .discord-author-info {
	color: #fff;
	display: inline-flex;
	align-items: center;
	font-size: 15px;
}

.discord-interaction .discord-interaction-author-info .discord-author-username {
	font-size: 1em !important;
	margin-right: 4px;
	opacity: 0.65;
}

.discord-interaction .discord-interaction-command .discord-interaction-command-name {
	color: #7289da;
	opacity: 0.65;
}

.discord-message .discord-author-avatar {
	margin-top: 1px;
	margin-right: 16px;
	min-width: 40px;
}

.slash-action span {
	font-family: Rajdhani, sans-serif;
	color: #fff;
	opacity: 0.7;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 15px;
}

.discord-message .discord-author-avatar img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.discord-message .discord-message-body {
	position: relative;
	width: 100%;
	white-space: pre-wrap;
}

.section-content-no-bg {
	background: none;
	box-shadow: none;
}

.discord-message .discord-author-info {
	color: #fff;
	display: inline-flex;
	align-items: center;
	font-size: 15px;
	margin-bottom: 0;
}

.discord-message .discord-author-info .discord-author-username {
	font-size: 1.1em;
	font-weight: 500;
	letter-spacing: 0.5px;
	margin-bottom: 0;
}

.discord-message .discord-author-info .discord-author-bot-tag {
	background-color: #5865f2;
	font-size: 0.65em;
	margin-left: 5px;
	padding: 3px 4px;
	border-radius: 3px;
	line-height: 100%;
	margin-bottom: 0;
	text-transform: uppercase;
}

.discord-message .discord-message-content .discord-message-timestamp {
	color: #72767d;
	font-size: 12px;
	margin-left: 3px;
}

.discord-message .discord-message-content {
	display: flex;
	width: 100%;
	line-height: 160%;
	font-weight: 400;
	overflow-wrap: anywhere;
}

.discord-slash-preview {
	height: 100%;
	color: #fff;
	position: relative;
}

.discord-text-input {
	padding-left: 16px;
}

.discord-text-input input {
	margin-bottom: 24px;
	background-color: #40444b;
	position: relative;
	width: 100%;
	text-indent: 0;
	border-radius: 8px;
	border: none;
	height: 44px;
	color: #dcddde;
	padding-bottom: 11px;
	padding-top: 11px;
}

.discord-embed {
	color: #ffffff;
	display: flex;
	margin-top: 8px;
	margin-bottom: 8px;
	font-size: 13px;
	line-height: 150%;
}

.discord-embed .discord-embed-left-border {
	background-color: #202225;
	flex-shrink: 0;
	width: 4px;
	border-radius: 4px 0 0 4px;
}

.discord-embed .discord-embed-container {
	background-color: #2f3136;
	display: flex;
	flex-direction: column;
	max-width: 520px;
	padding: 8px 16px 16px;
	border: 1px solid rgba(46, 48, 54, 0.6);
	border-radius: 0 4px 4px 0;
}

.discord-embed .discord-embed-content {
	display: flex;
}

.discord-embed .discord-embed-content .discord-embed-author {
	color: #fff;
	display: flex;
	align-items: center;
	font-weight: 500;
	margin-top: 8px;
	gap: 5px;
}

.discord-embed .discord-embed-content .discord-embed-author img {
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.discord-embed .discord-embed-content .discord-embed-author a {
	color: #fff;
	font-weight: 500;
}

.discord-embed .discord-embed-content .discord-embed-title {
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	margin-top: 8px;
}

.discord-embed .discord-embed-content .discord-embed-title a {
	/* color: #00b0f4; */
	color: white;
	font-weight: 600;
}

.discord-embed .discord-embed-content .discord-embed-title a:hover {
	color: #00b0f4;
}

.discord-embed .discord-embed-content .discord-embed-description {
	margin-top: 8px;
}

.discord-embed .discord-embed-content .discord-embed-image {
	max-width: 100%;
	margin-top: 16px;
	border-radius: 4px;
}

.discord-embed .discord-embed-content .discord-embed-thumbnail {
	max-width: 80px;
	max-height: 80px;
	margin-left: 16px;
	margin-top: 8px;
	border-radius: 4px;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: top center;
	object-position: top center;
	margin-left: auto;
	margin-right: 0;
}

.discord-embed .discord-embed-footer {
	color: white;
	display: flex;
	align-items: center;
	font-size: 0.85em;
	margin-top: 8px;
	gap: 5px;
}

.discord-embed .discord-embed-footer img {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.discord-buttons {
	display: flex;
	flex-wrap: wrap;
}

.discord-buttons a.discord-button,
.discord-buttons button.discord-button {
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 32px;
	min-width: 60px;
	font-size: 14px;
	font-weight: 500;
	font-family: Roboto, sans-serif;
	line-height: 16px;
	margin: 4px 8px 4px 0;
	padding: 2px 16px;
	border: 0;
	border-radius: 3px;
	box-sizing: border-box;
	cursor: pointer;
	transition: background-color 0.15s ease, color 0.15s ease;
}

.discord-buttons .discord-button.discord-button-primary {
	background-color: #5865f2;
}

.discord-buttons .discord-button.discord-button-link,
.discord-buttons .discord-button.discord-button-secondary {
	background-color: #4f545c;
}

.discord-buttons .discord-button.discord-button-success {
	background-color: #3ba55c;
}

.discord-buttons .discord-button.discord-button-danger {
	background-color: #ed4245;
}

.discord-buttons .discord-button.discord-button-link,
.discord-buttons .discord-button.discord-button-secondary {
	background-color: #4f545c;
}

.embed-builder-field-section {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.discord-embed-fields {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
